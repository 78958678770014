<template>

<div class="list">
	
	<div class="list-image" v-bgimage="item.thumb"></div>

	<div class="list-text">

		<div class="list-text-name">{{ item.name }}</div>
		<div class="list-text-text">{{ item.text }}</div>

	</div>

	<a :href="item.url" target="_blank" class="list-button">View</a>

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.list {
	width: 100%;
	border-bottom: 2px solid #eee;
	padding-bottom: 20px;
	display: flex;
}

.is-mobile .list {
	flex-direction: column;
	margin-bottom: 20px;
}

.list:last-child {
	border-bottom: 0px;
}

.list-image {
	width: 82px;
	height: 108px;
	flex-shrink: 0;
	background-color: #eee;
	background-size: cover;
	background-position: 50% 50%;
}

.is-mobile .list-image {
	margin: 0px auto;
}

.list-text {
	padding-left: 20px;
	padding-right: 20px;
	flex-grow: 1;
	padding-top: 10px;
}

.is-mobile .list-text {
	padding: 20px 0px;
}

.list-text-name {
	color: #1a234c;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	margin-bottom: 10px;
}

.is-mobile .list-text-name {
	font-size: 14px;
}

.list-text-text {
	color: #343434;
	font-size: 16px;
	line-height: 30px;
}

.is-mobile .list-text-text {
	font-size: 14px;
	line-height: 20px;
}

.list-button {
	width: 130px;
	flex-shrink: 0;
	font-size: 12px;
	color: #fff;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	text-align: center;
	line-height: 44px;
	height: 44px;
	border-radius: 22px;
	background-color: #1a234c;
	margin-top: 10px;
}

.is-mobile .list-button {
	margin: 0px auto;
}

</style>
